<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">

          <p>
            Без конденсатора невозможна работа практически ни одной электрической цепи или отдельного функционального
            блока в приборе. В преобразователях напряжения, согласующих, преобразующих, передающих и усилительных цепях
            – везде используются конденсаторы. Даже цифровая электроника не может работать без них.
          </p>
          <p>
            Рассмотрим же историю этого электронного компонента. Когда и кем он был впервые создан. Удивительно, но
            первый конденсатор, хоть и имел много общего с современными элементами (касательно физических свойств),
            совсем не был похож на них. Даже название у него было совершенно иное.
          </p>

          <div class="row mt-32">
            <div class="col">
              <v-card-button
                v-for="text in checkboxes"
                :key="text"
                :active="answers.includes(text)"
                no-gutter
                class="mb-8"
              >
                <v-checkbox
                  v-model="answers"
                  :value="text"
                  class="p-8"
                >
                  {{ text }}
                </v-checkbox>
              </v-card-button>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VCard,
  VCardButton,
  VCheckbox
} from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'

export default {
  name: 'SCheckbox',

  components: {
    LWidget,
    VButton,
    VCard,
    VCardButton,
    VCheckbox,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 12000
        }
      },
      answers: [],
      checkboxes: [
        'Какой процент ваших конкурентов имеет головной офис в Новосибирске?',
        'Какой процент компаний, имеющих головной офис в Новосибирске, не являются вашими конкурентами?',
        'Какой процент ваших не-конкурентов имеет головной офис в Новосибирске?',
        'Какой процент компаний, имеющих головной офис в Новосибирске, являются вашими конкурентами?',
        'Какой процент российских компаний являются вашими конкурентами?',
        'Какой процент российских компанийимеет головной офис в Новосибирске?'
      ]
    }
  },

  methods: {
    onClick () {
      console.log('ответ', this.answers.map(answer => answer))
    }
  }
}
</script>
